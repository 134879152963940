import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const AboutPageTemplate = ({title, content, contentComponent}) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className='section edge--top--reverse is-light-primary'>
        <div className='container'>
          <PageContent className='content' content={content} />
          <br />
          <p className={`has-text-centered`}>
            <Link className={`button is-primary is-large is-outlined`} to={'/#offerings'}>
              Services we offer
            </Link>
          </p>
          <br />
          <br />
        </div>
      </section>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default AboutPageTemplate
